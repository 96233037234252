export const toggleTemp = (tempUnit, setTempUnit) => {
  if (tempUnit === "C") {
    setTempUnit("F");
  }
  if (tempUnit === "F") {
    setTempUnit("C");
  }
};

export const calculateTemp = (tempNumber, unit) => {
  if (unit === "C") {
    return tempNumber.toFixed(1);
  }
  if (unit === "F") {
    return (parseFloat(tempNumber) * (9 / 5) + 32).toFixed(1);
  }
};

export const getMonth = (number) => {
  if (parseInt(number) === 1) {
    return "Jan";
  }
  if (parseInt(number) === 2) {
    return "Feb";
  }
  if (parseInt(number) === 3) {
    return "Mar";
  }
  if (parseInt(number) === 4) {
    return "Apr";
  }
  if (parseInt(number) === 5) {
    return "May";
  }
  if (parseInt(number) === 6) {
    return "Jun";
  }
  if (parseInt(number) === 7) {
    return "Jul";
  }
  if (parseInt(number) === 8) {
    return "Aug";
  }
  if (parseInt(number) === 9) {
    return "Sept";
  }
  if (parseInt(number) === 10) {
    return "Oct";
  }
  if (parseInt(number) === 11) {
    return "Nov";
  }
  if (parseInt(number) === 12) {
    return "Dec";
  }
};
