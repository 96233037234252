import cdh1GenePhoto from "../images/cdh1gene.png";
import recipeFoodClubPhoto from "../images/recipefoodclub.png";
import veganFoodTelAvivPhoto from "../images/veganfoodtelaviv.png";
import yogaClubPhoto from "../images/yogaclub.png";
import pythonGoogleDocs from "../images/python_google_docs.png";
import googleSheetsApiPython from "../images/google_sheets_api_python.png";
import flaskTutorial from "../images/flask_tutorial.png";
import twitterBot from "../images/twitter_bot.png";

export const lessonsObject = {
  private_lesson_frontend: {
    title: "Front-End Web Development",
    subtitle: "Private Lesson",
    icon: "fas fa-file-code",
    descriptionOne:
      "One-on-one tutoring for HTML, CSS, JavaScript, React, and other front-end technologies.",
    descriptionTwo:
      "Solve a bug, learn a concept, build something. You choose.",
  },
  private_lesson_backend: {
    title: "Server-Side Python Web Development",
    subtitle: "Private Lesson",
    icon: "fas fa-server",
    descriptionOne:
      "Private Python lessons for web development. Build a server, scrape the web, work with files, master the basics, and more.",
    descriptionTwo:
      "Learn Python, build a web application, fix your code. Your call.",
  },
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const fsProjects = [
  {
    photo: veganFoodTelAvivPhoto,
    title: "Vegan Food Tel Aviv",
    link: "https://www.veganfoodtelaviv.com",
    description:
      "Originally intended to be a web application for selling homemade vegan food, this project is now an application for sharing information about Tel Aviv and vegan food. Armed with a Raspberry Pi, Twitter bot, and Telegram bot, VFTA keeps you up to date on current weather conditions and time in Tel Aviv. With Search Engine Optimation in mind, VFTA collects information from Amazon, YouTube, and the New York Times to help visitors find vegan food based on online search data. Built with a React frontend, Python/Flask server, and custom CSS. The links are Amazon affiliate links. Although I am not vegan, I respect those who are.",
  },
  {
    photo: yogaClubPhoto,
    title: "Yoga Club",
    link: "https://www.yogainthewind.com",
    description:
      "Originally a WordPress blog, Yoga Club is a web application that helps yogis learn how to do yoga and also find information online about yoga practice. With Search Engine Optimization in mind, this project collects information from Amazon, YouTube, and the New York Times to help visitors find information related to their online searches. Built with a React frontend, this project features a Python/Flask server, Google Firebase authentication, and custom CSS. Committed yogi since 2007, focusing on Bikram and Iyengar.",
  },
  {
    photo: cdh1GenePhoto,
    title: "CDH1 Gene",
    link: "https://www.cdh1gene.com",
    description:
      "CDH1 Gene creates awareness about the CDH1 genetic mutations and total gastrectomy. It is a collection articles that shares my family's story regarding our CDH1 genetic mutation. This application attracts thousands of unique visitors each year. Originally a WordPress blog, CDH1 gene will someday be converted to a web application built using React and Python/Flask.",
  },
  {
    photo: recipeFoodClubPhoto,
    title: "Recipe Food Club",
    link: "https://www.recipefoodclub.com",
    description:
      "Recipe Food Club is a web application that allows users to build and share recipes. From a database of ingredients, users can create a recipe. For each recipe, Recipe Food Club calculates a food label that breaks down the nutritional information by serving and by recipe. You can create a grocery list, connect with other users, and explore other ways to interact with your food and beverages. Built with a React frontend, Python/Flask server, MongoDB database, and custom CSS. The links are Amazon affiliate links. This is my first fullstack project.",
  },
];

export const codingTutorials = [
  {
    photo: flaskTutorial,
    categories: ["python", "flask"],
    objective:
      "to create a flask app with a virtual environment and one route that returns a hello message",
    slug: "flask-tutorial-python",
    docId: "1906Q9u8h-nQtM00KvXwBOxfuYLMu2bizpQ3EI7F6VW8",
    gists: [
      {
        id: "cbb91176f08209609d813f0d7c8dc664",
        name: "app.py",
      },
    ],
  },
  {
    photo: pythonGoogleDocs,
    categories: ["python", "flask", "google"],
    objective:
      "to connect a flask application to a google doc and serve as json the title and text from the google doc",
    slug: "python-google-docs",
    docId: "1P2j8csV50EBdT49bc7FqHQIeZaq8NogYV_xTwyx8COg",
    gists: [
      {
        id: "57bf123007b6dbf7cc1d6480850742f3",
        name: "app.py",
      },
      {
        id: "83bacbdccad78f9b3548624d326dd2af",
        name: "googledocs.functions.py",
      },
      {
        id: "bc2bb63c41df5bad03e199487ac46636",
        name: "credentials.json",
      },
    ],
  },
  {
    photo: googleSheetsApiPython,
    categories: ["python", "flask", "google"],
    objective:
      "to connect a flask application to a google sheet and serve in json format the data from the google sheet",
    slug: "google-sheets-api-python",
    docId: "1PY_i7ykTZTZnIeUrPmBa4NV4xXKk7VS1LKvMzhQ6pNc",
    gists: [
      {
        id: "e651468cb91a6dde321ab6b2833a235f",
        name: "app.py",
      },
      {
        id: "e0fb6186b2f14aa71584f7a39ff704db",
        name: "googlesheets.functions.py",
      },
      {
        id: "bc2bb63c41df5bad03e199487ac46636",
        name: "credentials.json",
      },
    ],
  },
  {
    photo: twitterBot,
    categories: ["python", "twitter"],
    objective:
      "to write a Python applicaton that automatically tweets a message once a day from a database of messages",
    slug: "create-a-twitter-bot",
    docId: "1mbF7P3xTxgjAgBXKJYcrXq2TlhAJbF38t-dtnARTE8A",
    gists: [
      {
        id: "e5819d7744d71a8811105647f38b2495",
        name: "twitter_bot.config.py",
      },
      {
        id: "b513ceca4430d34e13ac4a4057873544",
        name: "app.py",
      },
      {
        id: "94885863e47a8b61ced516e52e9ab1b2",
        name: "twitter_bot.auto_tweet.py",
      },
      {
        id: "9d37af0c696543eeb78e65d96c26516c",
        name: "twitter_bot.messages.py",
      },
      {
        id: "38800ab147ca50f472d2ead5659d27eb",
        name: "twitter_bot.data.messages.json",
      },
    ],
  },
  {
    photo: "",
    categories: ["python", "flask", "mongodb"],
    objective: "coming soon",
    slug: "build-web-app-with-python",
    docId: "1CDaPIO5r1vTRol2jg7Btm5z2MkgmdArAKc5gdm_9KMo",
    gists: [],
  },
  {
    photo: "",
    categories: ["python", "flask", "javascript"],
    objective: "coming soon",
    slug: "build-a-website-with-python",
    docId: "1zq2JPjGXKdGAwaD7LAQwvT3ZXFmSjbRzw9RXOz62_8g",
    gists: [],
  },
];

export const favoriteThings = [
  {
    title: "Justin's Nut Butter Honey Peanut Butter",
    name: "Peanut Butter",
    photo:
      "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07DF1SJCD&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=jonegrossman-20&language=en_US",
    link: "https://amzn.to/3fyXCLn",
  },
  {
    title: "Pedialyte Electrolyte Powder",
    name: "Pedialyte",
    photo:
      "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07KKGFN47&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=jonegrossman-20&language=en_US",
    link: "https://amzn.to/2C5w4zy",
  },
  {
    title: "Night & Day Furniture Murphy Cube Cabinet Bed",
    name: "Murphy Bed",
    photo:
      "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B075P1VZVL&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=jonegrossman-20&language=en_US",
    link: "https://amzn.to/31smS0R",
  },
  {
    title: "SCOTTeVEST - RFID Blocking Vest with 26 Concealed Pockets",
    name: "Travel Vest",
    photo:
      "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00MTZATMS&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=jonegrossman-20&language=en_US",
    link: "https://amzn.to/3koRIA0",
  },
  {
    title:
      "Selfie Stick Tripod, UBeesize 51in Extendable Tripod Stand with Bluetooth Remote",
    name: "Phone Stand",
    photo:
      "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07NWC3L95&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=jonegrossman-20&language=en_US",
    link: "https://amzn.to/33C5xFm",
  },
];

export const theCardData = [
  {
    search_terms: "raspberry pi",
    slug: "r-pi",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi 3",
    slug: "pi3",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi beginner",
    slug: "raspberry-pi-price",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi ubuntu",
    slug: "raspberry-pi-ubuntu",
    number_requested: "10",
  },
  {
    search_terms: "arduino raspberry pi",
    slug: "arduino-raspberry-pi",
    number_requested: "10",
  },
  {
    search_terms: "buy raspberry pi 4",
    slug: "buy-raspberry-pi-4",
    number_requested: "10",
  },
  {
    search_terms: "buy raspberry pi 3",
    slug: "buy-raspberry-pi-3",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi chromecast",
    slug: "raspberry-pi-chromecast",
    number_requested: "10",
  },
  {
    search_terms: "raspberry p",
    slug: "raspberry-p",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi 3 model b v1 2",
    slug: "raspberry-pi-3-model-b-v1-2",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi 3 b v1 2",
    slug: "raspberry-pi-3-b-v1-2",
    number_requested: "10",
  },
  {
    search_terms: "arduino and raspberry pi",
    slug: "arduino-and-raspberry-pi",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi ubuntu mate",
    slug: "raspberry-pi-ubuntu-mate",
    number_requested: "10",
  },
  {
    search_terms: "pie 3",
    slug: "pie-3",
    number_requested: "10",
  },
  {
    search_terms: "smallest raspberry pi",
    slug: "smallest-raspberry-pi",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi charger",
    slug: "raspberry-pi-charger",
    number_requested: "10",
  },
  {
    search_terms: "plex pi",
    slug: "plex-pi",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi mesh network",
    slug: "raspberry-pi-mesh-network",
    number_requested: "10",
  },
  {
    search_terms: "pi top 3",
    slug: "pi-top-3",
    number_requested: "10",
  },
  {
    search_terms: "python projects",
    slug: "python",
    number_requested: "10",
  },
  {
    search_terms: "django projects",
    slug: "django",
    number_requested: "10",
  },
  {
    search_terms: "django python",
    slug: "django python",
    number_requested: "10",
  },
  {
    search_terms: "anaconda python",
    slug: "anaconda-python",
    number_requested: "10",
  },
  {
    search_terms: "opencv",
    slug: "opencv",
    number_requested: "10",
  },
  {
    search_terms: "matplotlib",
    slug: "matplotlib",
    number_requested: "10",
  },
  {
    search_terms: "python online",
    slug: "python-online",
    number_requested: "10",
  },
  {
    search_terms: "python programming",
    slug: "python-programming",
    number_requested: "10",
  },
  {
    search_terms: "learn python",
    slug: "learn-python",
    number_requested: "10",
  },
  {
    search_terms: "python 3",
    slug: "python-3",
    number_requested: "10",
  },
  {
    search_terms: "scikit learn",
    slug: "scikit-learn",
    number_requested: "10",
  },
  {
    search_terms: "tkinter",
    slug: "tkinter",
    number_requested: "10",
  },
  {
    search_terms: "pygame",
    slug: "pygame",
    number_requested: "10",
  },
  {
    search_terms: "python ide",
    slug: "python-ide",
    number_requested: "10",
  },
  {
    search_terms: "sklearn",
    slug: "sklearn",
    number_requested: "10",
  },
  {
    search_terms: "python requests",
    slug: "python-requests",
    number_requested: "10",
  },
  {
    search_terms: "pypi",
    slug: "pypi",
    number_requested: "10",
  },
  {
    search_terms: "python flask",
    slug: "python-flask",
    number_requested: "10",
  },
  {
    search_terms: "python tutor",
    slug: "python-tutor",
    number_requested: "10",
  },
  {
    search_terms: "python coding",
    slug: "python-coding",
    number_requested: "10",
  },
  {
    search_terms: "python for beginners",
    slug: "python-for-beginners",
    number_requested: "10",
  },
  {
    search_terms: "react javascript",
    slug: "react",
    number_requested: "10",
  },
  {
    search_terms: "react native",
    slug: "react-native",
    number_requested: "10",
  },
  {
    search_terms: "react js",
    slug: "react-js",
    number_requested: "10",
  },
  {
    search_terms: "react router",
    slug: "react-router",
    number_requested: "10",
  },
  {
    search_terms: "reactjs",
    slug: "reactjs",
    number_requested: "10",
  },
  {
    search_terms: "react hooks",
    slug: "react-hooks",
    number_requested: "10",
  },
  {
    search_terms: "react bootstrap",
    slug: "react-bootstrap",
    number_requested: "10",
  },
  {
    search_terms: "react redux",
    slug: "react-redux",
    number_requested: "10",
  },
  {
    search_terms: "react navigation",
    slug: "react-navigation",
    number_requested: "10",
  },
  {
    search_terms: "next js",
    slug: "next-js",
    number_requested: "10",
  },
  {
    search_terms: "react tutorial",
    slug: "react-tutorial",
    number_requested: "10",
  },
  {
    search_terms: "react jsx",
    slug: "jsx",
    number_requested: "10",
  },
  {
    search_terms: "react native tutorial",
    slug: "react-native-tutorial",
    number_requested: "10",
  },
  {
    search_terms: "react native navigation",
    slug: "react-native-navigation",
    number_requested: "10",
  },
  {
    search_terms: "react native firebase",
    slug: "react-native-firebase",
    number_requested: "10",
  },
  {
    search_terms: "javascript",
    slug: "javascript",
    number_requested: "10",
  },
  {
    search_terms: "node js",
    slug: "node-js",
    number_requested: "10",
  },
  {
    search_terms: "javascript",
    slug: "js",
    number_requested: "10",
  },
  {
    search_terms: "angularjs",
    slug: "angularjs",
    number_requested: "10",
  },
  {
    search_terms: "es6",
    slug: "es6",
    number_requested: "10",
  },
  {
    search_terms: "learn javascript",
    slug: "learn-javascript",
    number_requested: "10",
  },
  {
    search_terms: "javascript online",
    slug: "javascript-online",
    number_requested: "10",
  },
  {
    search_terms: "learn js",
    slug: "learn-js",
    number_requested: "10",
  },
  {
    search_terms: "arduino",
    slug: "arduino",
    number_requested: "10",
  },
  {
    search_terms: "esp8266 arduino",
    slug: "esp8266-arduino",
    number_requested: "10",
  },
  {
    search_terms: "esp32 arduino",
    slug: "esp32-arduino",
    number_requested: "10",
  },
  {
    search_terms: "arduino led",
    slug: "arduino-led",
    number_requested: "10",
  },
  {
    search_terms: "arduino kit",
    slug: "arduino-kit",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi kit",
    slug: "raspberry-pi-kit",
    number_requested: "10",
  },
  {
    search_terms: "i2c arduino",
    slug: "i2c-arduino",
    number_requested: "10",
  },
  {
    search_terms: "arduino projects for beginners",
    slug: "arduino-projects-for-beginners",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi projects for beginners",
    slug: "raspberry-pi-projects-for-beginners",
    number_requested: "10",
  },
  {
    search_terms: "cool arduino projects",
    slug: "cool-arduino-projects",
    number_requested: "10",
  },
  {
    search_terms: "cool raspberry pi projects",
    slug: "cool-raspberry-pi-projects",
    number_requested: "10",
  },
  {
    search_terms: "flask python",
    slug: "flask-python",
    number_requested: "10",
  },
  {
    search_terms: "flask python for beginners",
    slug: "flask-python-for-beginners",
    number_requested: "10",
  },
  {
    search_terms: "flask python tutorial for beignners",
    slug: "flask-python-tutorial-for-beginners",
    number_requested: "10",
  },
  {
    search_terms: "flask sqlalchemy",
    slug: "flask-sqlalchemy",
    number_requested: "10",
  },
  {
    search_terms: "flask python tutorial",
    slug: "flask-python-tutorial",
    number_requested: "10",
  },
  {
    search_terms: "flask app",
    slug: "flask-app",
    number_requested: "10",
  },
  {
    search_terms: "best flask tutorials",
    slug: "best-flask-tutorials",
    number_requested: "10",
  },
  {
    search_terms: "flask python 3",
    slug: "flask-python-3",
    number_requested: "10",
  },
  {
    search_terms: "python web app",
    slug: "python-web-app",
    number_requested: "10",
  },
  {
    search_terms: "on page seo",
    slug: "on-page-seo",
    number_requested: "10",
  },
  {
    search_terms: "ipr schedule",
    slug: "ipr-schedule",
    number_requested: "10",
  },
  {
    search_terms: "delaware entity search",
    slug: "delaware-entity-search",
    number_requested: "10",
  },
  {
    search_terms: "ingringement patent",
    slug: "ingringement-patent",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi camera",
    slug: "raspberry-pi-camera",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi sensors",
    slug: "raspberry-pi-sensors",
    number_requested: "10",
  },
  {
    search_terms: "raspberry pi robot",
    slug: "raspberry-pi-robot",
    number_requested: "10",
  },
];
