import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const ProductCards = (props) => {
  const { card } = props;
  const [title, setTitle] = useState("");

  useEffect(() => {
    const newTitle = card.slug
      .replace(/-/g, " ")
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");

    setTitle(newTitle);
  }, []);

  return (
    <Link to={`/products/${card.slug}`} className="card-link">
      <div className="card">
        <h3 className="center-text black-text">{title}</h3>
        <div className="card-button">Visit</div>
      </div>
    </Link>
  );
};

export default ProductCards;
