import React from "react";
import { Helmet } from "react-helmet";
import Billboard from "./Billboard";
import Projects from "../Projects/Projects";
import Products from "../Products/Products";
import TutorialsHome from "../Tutorials/TutorialsHome";

const Home = () => {
  return (
    <div className="flex-wrapper">
      <Helmet>
        <title>
          Jonathan Grossman | Fullstack Developer and Patent Attorney
        </title>
        <meta
          name="description"
          content={`Jonathan Grossman is a fullstack web developer and patent attorney. Please tell your friends.`}
        />
      </Helmet>

      <Billboard className="margin-bottom-20" />

      {/* <TutorialsHome
        flexDir={"flex-row"}
        cardSize={"not-wide"}
        numberToShow={6}
      /> */}
      {/* <h1>Projects</h1>
      <h3 className="center-text subtitle justify-text">
        The following is a list of my fullstack web applications. I use them
        mostly as a sandbox for learning new things, so they may at times lack
        some polish and completeness. For frontends, I use ReactJS, vanilla
        JavaScript, and custom CSS. For servers, I use Flask and Python. I am
        also familiar with NodeJS. For storage, I use MongoDB and Google
        Firebase. I am experienced with SQL too. Note: CDH1 Gene I built with
        WordPress.
      </h3> */}
      {/* <Projects /> */}
      {/* <h1>Product Pages</h1>
      <h3 className="center-text subtitle justify-text">
        The following is a list of pages I created based on my research of what
        words people are using in their Google searches. The product pages are
        intended to help drive traffic to my website by providing relevant
        information to people searching online. Each page aggregates relevant
        products from Amazon and videos from YouTube.
      </h3> */}
      {/* <Products /> */}
    </div>
  );
};

export default Home;
