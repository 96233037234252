import React from "react";

const TempToggle = (props) => {
  const { tempUnit } = props;
  return (
    <>
      <span
        className={
          tempUnit === "C"
            ? "degrees-unit degrees-selected"
            : "degrees-unit degrees-not-selected"
        }
      >
        °C
      </span>
      <span
        className={
          tempUnit === "F"
            ? "degrees-unit degrees-selected"
            : "degrees-unit degrees-not-selected"
        }
      >
        °F
      </span>
    </>
  );
};

export default TempToggle;
