import React, { PureComponent, useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import Spinner from "./Spinner";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import beachPhoto from "../../images/beach.jpg";
import TempToggle from "./TempToggle";
import MarsWeather from "./MarsWeather";

import { toggleTemp, calculateTemp, getMonth } from "../../lib/functions.js";

const Weather = () => {
  const appContext = useContext(AppContext);
  const [weather, setWeather] = useState();
  const [weeklyWeather, setWeeklyWeather] = useState();
  const [prettyDate, setPrettyDate] = useState();
  const [prettyTime, setPrettyTime] = useState();
  const [amPm, setAmPm] = useState("am");
  const [tempUnit, setTempUnit] = useState("C");
  const [taWeatherGraphArray, setTAWeatherGraphArray] = useState();

  useEffect(() => {
    fetch(`${appContext.URL_2}/get-current-weather`)
      .then((response) => response.json())
      .then((data) => setWeather(data[0]));
  }, []);

  useEffect(() => {
    if (weather) {
      let date = new Date(weather.created_time);

      let day = date.toDateString();
      setPrettyDate(day);

      let hours = date.getHours();
      let minutes = "0" + date.getMinutes();

      if (hours.length === 1) {
        let formattedTime = "0" + hours + ":" + minutes.substr(-2);
        setPrettyTime(formattedTime);
      } else {
        let formattedTime = hours + ":" + minutes.substr(-2);
        setPrettyTime(formattedTime);
      }

      if (parseInt(hours) >= 12) {
        setAmPm("pm");
      } else {
        setAmPm("am");
      }
    }
  }, [weather]);

  useEffect(() => {
    fetch(`${appContext.URL_2}/get-weekly-weather`)
      .then((response) => response.json())
      .then((data) => {
        setWeeklyWeather(data);
      });
  }, []);

  useEffect(() => {
    const tempArray = [];
    if (weeklyWeather) {
      Object.entries(weeklyWeather).map(([day, values]) => {
        return tempArray.push(values);
      });
    }
    setTAWeatherGraphArray(tempArray);
  }, [weeklyWeather]);

  return (
    <div className="flex-center">
      <div className="weather-outer-wrapper">
        <img src={beachPhoto} className="weather-photo" />
        {!weather && (
          <div className="weather-wrapper">
            <Spinner type="dots" />
          </div>
        )}
        {weather && (
          <div className="weather-wrapper">
            <h1 className="left-text margin-0">Tel Aviv, Israel</h1>
            <div className="flex-wrapper-horiz-end">
              <div className="weather-item-wrapper">
                <h3 className="silver-text margin-right-20">
                  {weather.humidity}%
                </h3>
                <h5 className="code-font">humidity</h5>
              </div>
              <div className="weather-item-wrapper">
                <h3 className="silver-text">
                  {calculateTemp(weather.temperature, tempUnit)}°{tempUnit}{" "}
                </h3>
                <h5 className="code-font">temperature</h5>
              </div>
            </div>
            <span
              onClick={() => toggleTemp(tempUnit, setTempUnit)}
              className="degrees-toggle"
            >
              <TempToggle tempUnit={tempUnit} />
            </span>
            <h5 className="margin-top-10 code-font">
              on {prettyDate} at {prettyTime}
              {amPm}
            </h5>
            <h5 className="margin-0 code-font">
              powered by a{" "}
              <a href="https://amzn.to/2T4YYou" className="">
                Raspberry Pi
              </a>
            </h5>
          </div>
        )}
      </div>
      <div className="weather-outer-wrapper-two">
        <h2 className="margin-bottom-20">Weather On Demand</h2>
        <h5 className="flex-wrapper-left code-font">
          What is the weather in Tel Aviv? Message to find out.
          <a
            href="https://t.me/tel_aviv_info_bot"
            className="nav-button  padding-10"
          >
            <i className="fab fa-telegram" />
          </a>
        </h5>
      </div>

      <div className="weather-outer-wrapper-two">
        <h2 className="margin-bottom-40">Recent Weather History</h2>
        {weeklyWeather && (
          <div className="weekly-weather-wrapper margin-bottom-40">
            <div className="day-weather-wrapper">
              <div className="weather-date">DAY</div>

              <h4 className="margin-bottom-20">humidity</h4>
              <h4 className="margin-bottom-20">
                temp
                <div
                  onClick={() => toggleTemp(tempUnit, setTempUnit)}
                  className="degrees-toggle"
                >
                  <TempToggle tempUnit={tempUnit} />
                </div>
              </h4>
            </div>
            {Object.entries(weeklyWeather).map(([day, values]) => {
              return (
                <div className="day-weather-wrapper">
                  <div className="weather-date">
                    {getMonth(day.slice(5, 7))} {day.slice(8, 10)}
                  </div>

                  <div className="margin-bottom-20">{values.humidity_avg}%</div>

                  <div className="margin-bottom-20">
                    {calculateTemp(values.temp_avg, tempUnit)}°{tempUnit}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {weeklyWeather && console.log(weeklyWeather)}
        {taWeatherGraphArray && prettyDate && (
          <div className="chart-wrapper">
            <LineChart
              width={650}
              height={300}
              data={taWeatherGraphArray}
              margin={{
                top: 5,
                right: 10,
                left: 10,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="0 10" />
              <XAxis dataKey="humidity" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="humidity_avg"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="temp_avg" stroke="#82ca9d" />
            </LineChart>
          </div>
        )}
      </div>
      {/* <h2 className="margin-bottom-40">Weather on Mars</h2>
      <MarsWeather /> */}
    </div>
  );
};

export default Weather;
