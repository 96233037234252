import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import AppContext from "../../context/AppContext";
import ProductsTable from "./ProductsTable";
import Products from "./Products";
import Videos from "./Videos";
import NewYorkTimesSearch from "./NewYorkTimesSearch";
import ProductDetails from "./ProductDetails";
import { theCardData } from "../../lib/lib.js";

const ProductPage = () => {
  const appContext = useContext(AppContext);
  const [formData, setFormData] = useState({});
  const [amazonProducts, setAmazonProducts] = useState([]);
  const [title, setTitle] = useState("");

  const getAmazonProducts = (data) => {
    axios
      .post(`${appContext.URL}/amazon/search-terms`, data)
      .then((res) => {
        if (res.status === 200) {
          setAmazonProducts(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  useEffect(() => {
    const newTitle = window.location.pathname
      .replace(/-/g, " ")
      .replace("/products/", "")
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    setTitle(newTitle);
  }, [window.location.pathname]);

  useEffect(() => {
    if (title) {
      let userRequest = {
        search_terms: "",
        number_requested: "1",
      };

      if (window.location.pathname) {
        theCardData.forEach((product) => {
          if (product.slug === window.location.pathname.slice(10)) {
            userRequest.search_terms = product.search_terms;
            userRequest.number_requested = product.number_requested;
          }
        });
        setFormData(userRequest);
      }
    }
  }, [title]);

  useEffect(() => {
    if (formData) {
      getAmazonProducts(formData);
    }
  }, [formData]);

  return (
    <div className="cards-wrapper">
      <Helmet>
        <title>Jonathan Grossman | {title ? title : "Jonathan Grossman"}</title>
        <meta
          name="description"
          content={`${title}. Learn more at Jonathan Grossman.`}
        />
      </Helmet>
      {title && amazonProducts && (
        <div className="flex-wrapper">
          <h1>{title}</h1>

          <h2 className="center-text">
            This page shows products and videos related to
          </h2>
          <h2 className="center-text products-subtitle">{title}</h2>

          <h3 className="center-text products-subtitle">
            Here is a summary of products advertised on this page. Read below
            and click the products to shop.
          </h3>

          <ProductsTable products={amazonProducts} />

          <h2 className="center-text products-subtitle">
            Looking for something else?
          </h2>

          <Products numberToShow={6} />

          <div>
            <h1 className="center-text products-subtitle">{title}</h1>
          </div>

          {amazonProducts &&
            amazonProducts.map((product) => {
              return <ProductDetails product={product} />;
            })}

          <h1 className="center-text products-subtitle">{title} Videos</h1>
          {formData && <Videos searchTerms={formData.search_terms} />}

          {/* <h1 className="center-text products-subtitle">{title}</h1>
          <h3 className="center-text products-subtitle">
            in the New York Times
          </h3> */}
          {/* {formData && (
            <NewYorkTimesSearch searchTerms={formData.search_terms} />
          )} */}
        </div>
      )}
    </div>
  );
};

export default ProductPage;
