import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import parse from "html-react-parser";
import axios from "axios";

const Videos = (props) => {
  const { searchTerms } = props;
  const appContext = useContext(AppContext);
  const [videos, setVideos] = useState([]);

  const getVideos = (name) => {
    axios
      .get(`${appContext.URL}/youtube-videos/${searchTerms}/`)
      .then((res) => {
        setVideos(res.data.videos);
      })
      .catch((err) => console.log(err.response.data, err.response.status));
  };

  useEffect(() => {
    if (searchTerms) {
      getVideos(searchTerms);
    }
  }, [searchTerms]);

  return (
    <div>
      {videos.map((video) => {
        let urlForFrame = parse(video.embed).props.src;
        return (
          <div className="video" key={video.id}>
            <a href={video.url} className="video-name">
              {video.title}
            </a>
            {window.innerWidth <= 700 && (
              <iframe
                width="305"
                height="177"
                src={"https:" + urlForFrame}
                frameborder="0"
              ></iframe>
            )}
            {window.innerWidth > 700 && (
              <iframe
                width="420"
                height="235"
                src={"https:" + urlForFrame}
                frameborder="0"
              ></iframe>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Videos;
