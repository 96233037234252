import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Parser from "html-react-parser";
import AppContext from "../../context/AppContext";
import { codingTutorials } from "../../lib/lib";
import Gist from "react-gist";
import Spinner from "../Main/Spinner";

const TutorialPage = () => {
  const appContext = useContext(AppContext);
  let location = useLocation();
  const [tutorialForDisplay, setTutorialForDispaly] = useState(null);
  const [tutorialFromDocs, setTutorialFromDocs] = useState(null);

  const getTutorial = (docId) => {
    fetch(appContext.URL_2 + `/get-google-doc-text/${docId}/`)
      .then((response) => response.json())
      .then((data) => {
        setTutorialFromDocs(data);
      });
  };

  useEffect(() => {
    if (location.state) {
      setTutorialForDispaly(location.state.tutorial);
      getTutorial(location.state.tutorial.docId);
    } else {
      let urlSlug = location.pathname.replace("/tutorials/", "");
      let tutorial = codingTutorials.find((x) => x.slug === urlSlug);
      if (tutorial) {
        setTutorialForDispaly(tutorial);
        getTutorial(tutorial.docId);
      }
    }
  }, []);

  return (
    <div className="">
      {tutorialForDisplay && (
        <Helmet>
          <title>
            Jonathan Grossman | {tutorialForDisplay.slug.replace(/-/g, " ")}
          </title>
          <meta
            name="description"
            content={`${tutorialForDisplay.slug.replace(
              /-/g,
              " "
            )}. Looking for coding tutorials? I write tutorials on Python, Flask, JavaScript, React, Google APIs, and more. Please tell your friends.`}
          />
        </Helmet>
      )}

      {!tutorialForDisplay || (!tutorialFromDocs && <Spinner type="letters" />)}
      {tutorialForDisplay && tutorialFromDocs && (
        <div className="tutorial-wrapper">
          <h1>{tutorialForDisplay.slug.replace(/-/g, " ")}</h1>
          <img src={tutorialForDisplay.photo} className="tutorial-image" />
          <h2>{tutorialFromDocs.title}</h2>
          <div className="tutorial-subtitle">
            Objective: {tutorialForDisplay.objective}
          </div>
          <div class="tutorial-heading">Code Snippets</div>
          <div className="tutorial-step">
            The following are code snippets used in this tutorial. I highly
            recommend you read them before doing the tutorial. I provide them at
            the beginning of the tutorial becuase I believe it is beneficial for
            learning to have some experience with the code before getting the
            explanation. Although you are welcome to copy the code into your own
            projects, if you are new to Python or Flask, I recommend you type
            the code yourself to help better understand the materials.
          </div>

          {tutorialForDisplay.gists.map((gist) => {
            return (
              <div>
                <div className="tutorial-step">{gist.name}</div>
                <Gist id={gist.id} />
              </div>
            );
          })}
          <div>{Parser(tutorialFromDocs.text)}</div>
          {/* <div>{Parser(marked(tutorialFromDocs.text))}</div> */}
        </div>
      )}
    </div>
  );
};

export default TutorialPage;
