import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import AppContext from "../../context/AppContext";
import Spinner from "../../components/Main/Spinner";
import CodeBlock from "../../components/Main/CodeBlock";

const PythonBasicsLesson = (props) => {
  const appContext = useContext(AppContext);
  const [theFile, setTheFile] = useState("");
  const [theLesson, setTheLesson] = useState("");
  const [lessonsList, setLessonsList] = useState([]);
  const [theLessonType, setTheLessonType] = useState();

  const [prevButton, setPrevButton] = useState(0);
  const [nextButton, setNextButton] = useState(0);
  const [aTags, setATags] = useState([]);
  const [sectionsArray, setSectionsArray] = useState([]);

  const location = useLocation();

  const onScroll = (e) => {
    // console.log(e);
    // setScrollTop(e.target.documentElement.scrollTop);
    // setScrolling(e.target.documentElement.scrollTop > scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const elements = document.getElementsByTagName("a");
    setATags(Array.from(elements));
  }, [theLesson]);

  useEffect(() => {
    let tempArray = [];
    aTags.forEach((element) => {
      // create array of section headings
      if (element.hash && !element.classList.contains("section-link")) {
        const sectionTitle = element.hash
          .replace("#", "")
          .replace(/-/g, " ")
          .replace(/python/g, "Python");

        const polishedTitle =
          sectionTitle.charAt(0).toUpperCase() + sectionTitle.substr(1);

        const sectionObject = {
          title: polishedTitle,
          hash: element.hash,
        };

        tempArray.push(sectionObject);
      }
    });
    setSectionsArray(tempArray);
  }, [aTags]);

  useEffect(() => {
    // if not a hash link scroll to top

    aTags.forEach((element) => {
      // scroll to section
      if (element.hash === location.hash) {
        element.scrollIntoView();
      }
      // scroll to top
      if (location.hash === "") {
        window.scrollTo(0, 0);
      }
    });
  }, [location.hash]);

  const getFileContents = (file, lessonType) => {
    fetch(appContext.URL_2 + `/get-lesson${lessonType}/${file}/`)
      .then((response) => response.json())
      .then((data) => {
        setTheLesson(data);
      });
  };

  // useEffect(() => {
  //   if (theLessonType) {
  //     getFile();
  //   }
  // }, [theLessonType]);

  useEffect(() => {
    if (theLessonType) {
      fetch(appContext.URL_2 + theLessonType)
        .then((response) => response.json())
        .then((data) => {
          setLessonsList(data);
        });
    }
  }, [theFile]);

  const getLesson = () => {
    // when page loads, check if pathname contains lesson name
    if (props.location.state === undefined) {
      const pathname_here = props.location.pathname;
      const indexOfSecondForwardSlash = pathname_here.indexOf("/", 1);
      const lesson_type = pathname_here.slice(0, indexOfSecondForwardSlash);
      setTheLessonType(lesson_type);

      const lesson_file =
        pathname_here.slice(indexOfSecondForwardSlash + 1) + ".md";
      getFileContents(lesson_file, lesson_type);
      setTheFile(lesson_file);

      // getListLessons();
    } else {
      // it's in the state
      const lesson_type = location.state.lesson_type;
      setTheLessonType(lesson_type);

      const file_name = props.location.state.lesson_file;
      getFileContents(file_name, lesson_type);
      setTheFile(file_name);
      // setLessonsList(props.location.state.lessons);
      // getListLessons();
    }
  };

  const getListLessons = () => {
    fetch(appContext.URL_2 + `${theLessonType}/`)
      .then((response) => response.json())
      .then((data) => {
        setLessonsList(data);
      });
  };

  useEffect(() => {
    getLesson();
  }, [props.location.pathname]);

  useEffect(() => {
    if (lessonsList && theFile) {
      const file_index = lessonsList.indexOf(theFile);
      if (file_index === 0) {
        setPrevButton(null);
        setNextButton(file_index + 1);
      } else if (file_index === lessonsList.length - 1) {
        setPrevButton(file_index - 1);
        setNextButton(null);
      } else {
        setPrevButton(file_index - 1);
        setNextButton(file_index + 1);
      }
    }
  }, [lessonsList, theFile]);

  return (
    <>
      {theLessonType && (
        <Link to={theLessonType} className="lesson-home-button">
          {`<<< ${theLessonType.replace(/\//g, "").replace(/-/g, " ")} lesson`}
        </Link>
      )}
      {!theLesson && (
        <div className="lesson-wrapper">
          <Spinner />
        </div>
      )}
      <div className="">
        <div className="sections-wrapper">
          <a href="#" className="section-link">
            {theFile.charAt(3).toUpperCase() +
              theFile.slice(4, -3).replace(/_/g, " ")}
          </a>
          {sectionsArray.map((section) => {
            return (
              <a
                href={section.hash}
                className="section-link"
                key={section.title}
              >
                {section.title}
              </a>
            );
          })}
        </div>
        <ReactMarkdown
          source={theLesson}
          renderers={{ code: CodeBlock }}
          className="lesson-wrapper-col"
        />
      </div>

      <div className="lesson-buttons">
        <div className="prev-button">
          {theLessonType && theLesson && lessonsList[prevButton] && (
            <Link
              to={`${theLessonType}/${lessonsList[prevButton].slice(0, -3)}`}
              className="tutorial-text-link"
              onClick={() => setTheLesson("")}
            >
              {"<<< " + lessonsList[prevButton].slice(3, -3).replace(/_/g, " ")}
            </Link>
          )}
        </div>
        <div className="next-button">
          {theLessonType && theLesson && lessonsList[nextButton] && (
            <Link
              to={`${theLessonType}/${lessonsList[nextButton].slice(0, -3)}`}
              className="tutorial-text-link"
              onClick={() => setTheLesson("")}
            >
              {lessonsList[nextButton].slice(3, -3).replace(/_/g, " ")} {">>>"}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default PythonBasicsLesson;
