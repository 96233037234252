import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import AppContext from "../../context/AppContext";

const Navbar = () => {
  const appContext = useContext(AppContext);
  const [showMenu, setShowMenu] = useState(false);

  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <div className="navbar-dark-strip"></div>
      <div className="nav-wrapper">
        <div className="navbar">
          <div className="navbar-inner-wrapper-left">
            <div className="navbar-flex">
              <Link to="/" className="nav-button logo-no-toggle padding-7">
                JG
              </Link>
              {/* <a
                href="https://www.facebook.com/jonathanegrossman"
                className="nav-button logo-no-toggle padding-10"
              >
                <i className="fa fa-facebook-f" />
              </a>
              <a
                href="https://www.instagram.com/grossman_jon/"
                className="nav-button logo-no-toggle padding-10"
              >
                <i className="fa fa-instagram" />
              </a>
              <a
                href="https://github.com/JonathanGrossman"
                className="nav-button logo-no-toggle padding-10"
              >
                <i className="fa fa-github" />
              </a>
              <a
                href="https://il.linkedin.com/in/jonathan-grossman-06867a4"
                className="nav-button logo-no-toggle padding-10"
              >
                <i className="fa fa-linkedin" />
              </a>
              <a
                href="https://twitter.com/jonegrossman"
                className="nav-button logo-no-toggle padding-10"
              >
                <i className="fa fa-twitter" />
              </a> */}
              <div
                className="nav-button logo-toggle padding-7"
                onClick={() => handleMenu()}
              >
                JG
              </div>
            </div>

            {showMenu && (
              <div className="navbar-flex logo-toggle padding-10">
                <div>
                  <Link to="/" className="nav-button  padding-7">
                    Home
                  </Link>
                  <Link to="/lessons" className="nav-button  padding-7">
                    Lessons
                  </Link>
                  <Link to="/weather" className="nav-button  padding-7">
                    Weather
                  </Link>
                </div>

                {/* <a
                  href="https://www.facebook.com/jonathanegrossman"
                  className="nav-button  padding-10"
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  href="https://www.instagram.com/grossman_jon/"
                  className="nav-button padding-10"
                >
                  <i className="fab fa-instagram" />
                </a>
                <a
                  href="https://github.com/JonathanGrossman"
                  className="nav-button padding-10"
                >
                  <i className="fab fa-github" />
                </a>
                <a
                  href="https://il.linkedin.com/in/jonathan-grossman-06867a4"
                  className="nav-button padding-10"
                >
                  <i className="fab fa-linkedin" />
                </a>
                <a
                  href="https://twitter.com/jonegrossman"
                  className="nav-button padding-10"
                >
                  <i className="fab fa-twitter" />
                </a> */}
              </div>
            )}
          </div>
          <div className="navbar-inner-wrapper-right">
            <Link to="/lessons" className="nav-button logo-no-toggle padding-7">
              Lessons
            </Link>
            <Link to="/weather" className="nav-button logo-no-toggle padding-7">
              Weather
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
