import React from "react";
import buyButton from "../../images/AmazonButton.png";

const ProductDetails = (props) => {
  const { product } = props;

  return (
    <div className="product-details">
      <a href={product.detail_page_url} className="product-link">
        <img src={product.images._url} className="product-image" />
        <h2>{product.title}</h2>
        <div className="card-buy-wrapper">
          <div className="product-price">{product.buying_price}</div>
          <img src={buyButton} className="amazon-button" />
        </div>
      </a>
      {product.features && (
        <div className="product-features">
          {product.features.map((feature) => {
            return feature;
          })}
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
