import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AppContext from "../../context/AppContext";

const PythonBasics = () => {
  const appContext = useContext(AppContext);
  const [pyLessons, setPyLessons] = useState([]);
  const [linkLesson, setLinkLesson] = useState();
  const location = useLocation();

  useEffect(() => {
    setLinkLesson(location.pathname);
    fetch(appContext.URL_2 + location.pathname)
      .then((response) => response.json())
      .then((data) => {
        setPyLessons(data);
      });
  }, []);

  useEffect(() => {
    console.log(linkLesson);
  }, [linkLesson]);

  return (
    <div>
      <Link to={`/lessons`} className="lesson-home-button">
        {"<<< " + "Lessons Home"}
      </Link>
      {linkLesson && (
        <h1 className="lesson-title">
          {linkLesson.replace(/\//g, "").replace(/-/g, " ")} lesson
        </h1>
      )}
      <div className="lesson-list">
        {pyLessons.map((lesson) => {
          return (
            <Link
              to={{
                pathname: `${linkLesson}/${lesson.slice(0, -3)}`,
                state: {
                  lesson_file: lesson,
                  lessons: pyLessons,
                  lesson_type: linkLesson,
                },
              }}
              className="lesson-list-button font-size-24"
            >
              {/* build the name for the list */}
              {(
                lesson.charAt(0) +
                lesson.charAt(1) +
                ". " +
                lesson.charAt(3).toUpperCase() +
                lesson.slice(4, -3)
              ).replace(/_/g, " ")}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default PythonBasics;
