import React from "react";
import { Link } from "react-router-dom";
import { lessonsObject } from "../../lib/lib";

const LessonsHome = () => {
  //   add lesson routes to lessonsArray

  const lessonsArray = [
    {
      icon: "fab fa-python",
      slug: `python-basics`,
    },
    {
      icon: "fab fa-github",
      slug: `github-basics`,
    },
    // {
    //   icon: "fab fa-raspberry-pi",
    //   slug: `raspberry-pi`,
    // },
    // {
    //   icon: "fab fa-html5",
    //   slug: `html-basics`,
    // },
    // {
    //   icon: "fab fa-css3",
    //   slug: `css-basics`,
    // },
    // {
    //   icon: "fab fa-github",
    //   slug: `github-basics`,
    // },
    // {
    //   icon: "fab fa-js",
    //   slug: `javascript-basics`,
    // },
    // {
    //   icon: "fab fa-node",
    //   slug: `nodejs-basics`,
    // },
    // {
    //   icon: "fab fa-react",
    //   slug: `react-basics`,
    // },
  ];

  return (
    <>
      <Link to={`/`} className="lesson-home-button">
        {"<<< " + "Home"}
      </Link>
      <h1 className="lesson-title">Learn to Code</h1>
      <h2 className="lesson-title">Live Instruction</h2>
      <div className="lesson-list">
        {Object.entries(lessonsObject).map(([key, value]) => (
          <Link
            to={`/checkout/${key}`}
            className="lesson-list-button font-size-24"
            key={key}
          >
            <i className={value.icon + " " + "width-40"} />
            {value.title}
          </Link>
        ))}
      </div>
      <h2 className="lesson-title">Online Tutorials (under construction)</h2>
      <div className="lesson-list">
        {lessonsArray.map((lesson) => {
          return (
            <Link
              to={`/${lesson["slug"]}`}
              className="lesson-list-button font-size-24"
            >
              <i className={lesson["icon"] + " " + "width-40"} />
              {(
                lesson["slug"].charAt(0).toUpperCase() + lesson["slug"].slice(1)
              ).replace(/-/g, " ")}
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default LessonsHome;
