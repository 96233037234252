import React, { useEffect, useState } from "react";
import ProductCard from "../Products/ProductCard";
import { theCardData, shuffleArray, getAmazonProducts } from "../../lib/lib";

const Products = (props) => {
  const { numberToShow } = props;
  const [products, setProducts] = useState([]);

  const getSubsetProducts = () => {
    let shuffledArr = shuffleArray(theCardData);
    let newArray = [];
    shuffledArr.forEach((p, i) => {
      if (i < numberToShow) {
        newArray.push(p);
      }
      setProducts(newArray);
    });
  };

  useEffect(() => {
    if (!numberToShow) {
      let shuffledArr = shuffleArray(theCardData);
      setProducts(shuffledArr);
    } else {
      getSubsetProducts();
    }
  }, []);

  // useEffect(() => {
  //   const formData = {
  //     search_terms: "yoga equipment",
  //     number_requested: `10`,
  //   };
  //   getAmazonProducts(formData, setProductsImage);
  // }, [numberToShow]);

  // useEffect(() => {
  //   if (numberToShow) {
  //     console.log(productsImage);
  //   }
  // }, [numberToShow]);

  return (
    <div className="extra-margin billboard">
      {products && (
        <div className="cards-wrapper">
          {products.map((card) => {
            return <ProductCard card={card} key={card.slug} />;
          })}
        </div>
      )}
    </div>
  );
};

export default Products;
