import React, { useContext, useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

import AppContext from "../../context/AppContext";

import "../../css/global.css";
import "../../css/normalize.css";

import { lessonsObject } from "../../lib/lib";

const fetchCheckoutSession = async ({ quantity, lesson }) => {
  return fetch(`http://localhost:2000/create-checkout-session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      quantity,
      lesson,
    }),
  }).then((res) => res.json());
};

const formatPrice = ({ amount, currency, quantity }) => {
  const numberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === "decimal") {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  const total = (quantity * amount).toFixed(2);
  return numberFormat.format(total);
};

function reducer(state, action) {
  switch (action.type) {
    case "useEffectUpdate":
      return {
        ...state,
        ...action.payload,
        price: formatPrice({
          amount: action.payload.unitAmount,
          currency: action.payload.currency,
          quantity: state.quantity,
        }),
      };
    case "increment":
      return {
        ...state,
        quantity: state.quantity + 1,
        price: formatPrice({
          amount: state.unitAmount,
          currency: state.currency,
          quantity: state.quantity + 1,
        }),
      };
    case "decrement":
      return {
        ...state,
        quantity: state.quantity - 1,
        price: formatPrice({
          amount: state.unitAmount,
          currency: state.currency,
          quantity: state.quantity - 1,
        }),
      };
    case "setLoading":
      return { ...state, loading: action.payload.loading };
    case "setError":
      return { ...state, error: action.payload.error };
    default:
      throw new Error();
  }
}

const Checkout = () => {
  const appContext = useContext(AppContext);
  let location = useLocation();
  const [buyLesson, setBuyLesson] = useState();
  const [lessonDetails, setLessonDetails] = useState();

  // const lessonsObject = {
  //   solo_lesson: {
  //     title: "Front-End Web Development",
  //     subtitle: "Private Lesson",
  //     descriptionOne:
  //       "One-on-one tutoring for HTML, CSS, JavaScript, React, and other front-end technologies.",
  //     descriptionTwo:
  //       "Solve a bug, learn a concept, or build something. You choose.",
  //   },
  // };

  const [state, dispatch] = useReducer(reducer, {
    quantity: 1,
    price: null,
    loading: false,
    error: null,
    stripe: null,
  });

  useEffect(() => {
    let lowerCaseLesson = location.pathname.replace("/checkout/", "");

    let lessonObject = lessonsObject[lowerCaseLesson];
    setLessonDetails(lessonObject);

    let upperCaseLesson = lowerCaseLesson.toUpperCase();
    setBuyLesson(upperCaseLesson);
  }, [location]);

  useEffect(() => {
    if (buyLesson) {
      fetchConfig();
    }
  }, [buyLesson]);

  async function fetchConfig() {
    // Fetch config from our backend.
    const { publicKey, unitAmount, currency } = await fetch(
      `${appContext.URL_3}/config/${buyLesson}`
    ).then((res) => res.json());
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
    dispatch({
      type: "useEffectUpdate",
      payload: { unitAmount, currency, stripe: await loadStripe(publicKey) },
    });
  }

  const handleClick = async (event) => {
    // Call your backend to create the Checkout session.
    dispatch({ type: "setLoading", payload: { loading: true } });
    const { sessionId } = await fetchCheckoutSession({
      quantity: state.quantity,
      lesson: buyLesson,
    });
    // When the customer clicks on the button, redirect them to Checkout.
    const { error } = await state.stripe.redirectToCheckout({
      sessionId,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
    if (error) {
      dispatch({ type: "setError", payload: { error } });
      dispatch({ type: "setLoading", payload: { loading: false } });
    }
  };

  return (
    <div>
      {lessonDetails && (
        <div className="lesson-purchase">
          <div className="purchase-title-wrapper">
            <img
              alt="Random asset from Picsum"
              src="https://picsum.photos/280/320?random=4"
              className="random-image"
            />
            <div>
              <h2>{lessonDetails.title}</h2>
              <h3>{lessonDetails.subtitle}</h3>
            </div>
          </div>
          <div className="purchase-description-wrapper">
            <h3 className="purchase-description-one">
              {lessonDetails.descriptionOne}
            </h3>
            <div className="purchase-description-two">
              {lessonDetails.descriptionTwo}
            </div>
          </div>
          <section className="lesson-purchase">
            <div className="navbar-flex margin-bottom-40">
              <h2>Contact me</h2>
              <a
                href="https://il.linkedin.com/in/jonathan-grossman-06867a4"
                className="nav-button padding-10"
              >
                <i className="fab fa-linkedin" />
              </a>
            </div>
          </section>
          {/* <section className="purchase-card">
            <div className="navbar-flex padding-left-5 margin-bottom-40">
              <h2>Contact me</h2>
              <a
                href="https://il.linkedin.com/in/jonathan-grossman-06867a4"
                className="nav-button padding-10"
              >
                <i className="fab fa-linkedin" />
              </a>
            </div>
            {/* <div className="quantity-setter">
              <button
                className="increment-btn"
                disabled={state.quantity === 1}
                onClick={() => dispatch({ type: "decrement" })}
              >
                -
              </button>
              <input
                type="number"
                id="quantity-input"
                min="1"
                max="10"
                value={state.quantity}
                readOnly
              />
              <button
                className="increment-btn"
                disabled={state.quantity === 10}
                onClick={() => dispatch({ type: "increment" })}
              >
                +
              </button>
            </div>
            <p className="quantity-text">Number of 1-hour lessons (max 10)</p>

            <button
              role="link"
              onClick={handleClick}
              disabled={!state.stripe || state.loading}
            >
              {state.loading || !state.price
                ? `Loading...`
                : `Buy for ${state.price}`}
            </button>
            <div className="sr-field-error">{state.error?.message}</div> */}
          {/* </section> */}
        </div>
      )}
    </div>
  );
};

export default Checkout;
