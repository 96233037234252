import React from "react";
import buyButton from "../../images/AmazonButton.png";

const ProductsTable = (props) => {
  const { products } = props;
  return (
    <div className="products-table">
      {products.map((product) => {
        return (
          <a href={product.detail_page_url} className="product-link-table">
            <img src={product.images._url} className="product-image-small" />
            <div className="product-price-small">{product.buying_price}</div>
            <img src={buyButton} className="amazon-button" />
          </a>
        );
      })}
    </div>
  );
};

export default ProductsTable;
