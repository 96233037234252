import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import momImage from "../../images/mom.jpg";
import Weather from "./Weather";

const Billboard = () => {
  const [profileImage, setProfileImage] = useState(momImage);
  const [isSelected, setIsSelected] = useState("Fullstack Developer");

  const GITHUB_URL = "https://api.github.com/users/JonathanGrossman";

  useEffect(() => {
    axios
      .get(GITHUB_URL)
      .then((res) => {
        if (res.status === 200) {
          setProfileImage(res.data.avatar_url);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="intro-wrapper">
        <div className="margin-bottom-20">
          {profileImage && (
            <img
              src={profileImage}
              className="profile-image margin-bottom-20"
            />
          )}
        </div>
        <div>
          <h1 className="my-name">Jonathan Grossman</h1>
          <h3 className="my-name silver-text margin-bottom-20">
            Fullstack Developer | Attorney
          </h3>
          <div className="navbar-flex padding-left-5 margin-bottom-40">
            <a
              href="https://www.facebook.com/jonathanegrossman"
              className="nav-button  padding-10"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              href="https://www.instagram.com/grossman_jon/"
              className="nav-button padding-10"
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              href="https://github.com/JonathanGrossman"
              className="nav-button padding-10"
            >
              <i className="fab fa-github" />
            </a>
            <a
              href="https://il.linkedin.com/in/jonathan-grossman-06867a4"
              className="nav-button padding-10"
            >
              <i className="fab fa-linkedin" />
            </a>
            <a
              href="https://twitter.com/jonegrossman"
              className="nav-button padding-10"
            >
              <i className="fab fa-twitter" />
            </a>
          </div>
        </div>
      </div>
      {/* <h1>About</h1>
      <div className="flex-wrapper billboard padding-20">
        <div className="buttons-wrapper">
          <div
            onClick={(e) => {
              setIsSelected(e.target.textContent);
            }}
            className={
              `${isSelected}` === "Fullstack Developer"
                ? "billboard-button is-selected"
                : "billboard-button "
            }
          >
            Fullstack Developer
          </div>
          <div
            onClick={(e) => {
              setIsSelected(e.target.textContent);
            }}
            className={
              `${isSelected}` === "CDH1 Mutant"
                ? "billboard-button is-selected"
                : "billboard-button "
            }
          >
            CDH1 Mutant
          </div>
          <div
            onClick={(e) => {
              setIsSelected(e.target.textContent);
            }}
            className={
              `${isSelected}` === "Patent Attorney"
                ? "billboard-button is-selected"
                : "billboard-button "
            }
          >
            Patent Attorney
          </div>
        </div>
        <div className="bio-wrapper">
          {isSelected === "Fullstack Developer" && (
            <div className="bio-item">
              A self-taught developer, I spent nights and weekends learning to
              code. After a year plus of self-learning, I attended a fullstack
              coding bootcamp to sharpen my skills and connect with like-minded
              people. I write web applications in vanilla JavaScript, ReactJS,
              Python, Flask, MongoDB, Firebase, and SQL. I also build projects
              with the Raspberry Pi. As I build and learn, I write tutorials to
              share my knowledge and experience. I also work as a training
              specialist at{" "}
              <a href="https://www.itc.tech/" className="tutorial-text-link">
                Israel Tech Challenge{" "}
              </a>{" "}
              in the fullstack web development program.
            </div>
          )}
          {isSelected === "CDH1 Mutant" && (
            <div className="bio-item">
              In 2018, after my mom died from stomach cancer, I had my stomach
              removed. Because of her illness, we learned she and I each have a
              genetic mutation that predisposes us to a 70% chance of getting
              diffuse gastric cancer (HDGC). Like for my mom, HDGC is nearly
              impossible to detect until too late. It is also impossible to
              predict which mutants will develop the disease and when.
              Accordingly, many CDH1 mutation carriers have their stomach
              removed. I am raising awareness.
              <a href="https://www.cdh1gene.com" className="billboard-link">
                Please read more about our CDH1 story
              </a>{" "}
              and spread the word.
            </div>
          )}
          {isSelected === "Patent Attorney" && (
            <div className="bio-item">
              Before changing careers, I practiced law for more than 10 years in
              New York and Chicago. As a patent attorney, I taught complicated
              technologies to non-technical judges, attorneys, and clients. I
              focused on patent litigation and due diligence for pharmaceutical
              and medical device clients. I worked in BigLaw, as in-house
              counsel, as a solo practioner, and at a litigation boutique. I
              advised clients big and small and worked on many complex issues.
              My work took me to Europe, India, Scandanavia, and Israel.
            </div>
          )}
        </div>
      </div> */}
    </>
  );
};

export default Billboard;
