import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AppContext from "./context/AppContext";
import Home from "./components/Main/Home";
import Navbar from "./components/Main/Navbar";
import Footer from "./components/Main/Footer";
import LeftSideBar from "./components/Sidebars/LeftSideBar";
import Weather from "./components/Main/Weather";
import TutorialsHome from "./components/Tutorials/TutorialsHome";
import TutorialPage from "./components/Tutorials/TutorialPage";
import LessonsHome from "./components/Lessons/LessonsHome";
import PythonBasics from "./components/Lessons/PythonBasics";
import PythonBasicsLesson from "./components/Lessons/PythonBasicsLesson";
import ProductsHome from "./components/Products/ProductsHome";
import ProductPage from "./components/Products/ProductPage";
import NotFound from "./components/Main/NotFound";
import Checkout from "./components/Stripe/Checkout";
import Success from "./components/Stripe/Success";
import Canceled from "./components/Stripe/Canceled";

import "./App.css";

const App = () => {
  // amazon and youtube
  const URL = "https://shielded-reef-94276.herokuapp.com";
  // const URL = "http://localhost:4000";

  const URL_2 = "https://sheltered-brushlands-16281.herokuapp.com";
  // const URL_2 = "http://localhost:2000";

  const URL_3 = "http://localhost:2000";

  return (
    <div className="app">
      <Router>
        <AppContext.Provider
          value={{
            URL,
            URL_2,
            URL_3,
          }}
        >
          <Navbar />
          {/* <LeftSideBar /> */}
          <div className="app-content">
            {/* <Route component={NotFound} /> */}
            {/* tutorials home */}
            <Route
              exact
              path="/tutorials"
              component={() => (
                <TutorialsHome
                  flexDir={"flex-col"}
                  cardSize={"wide"}
                  numberToShow={0}
                />
              )}
            />
            {/* route for weather */}
            <Route exact path="/weather" component={Weather} />
            {/* routes for specific tutorials */}
            <Route exact path="/tutorials/:slug" component={TutorialPage} />
            {/* lessons homepage */}
            <Route exact path="/lessons" component={LessonsHome} />
            {/* add lesson routes to lessonsArray in LessonsHome */}
            <Route exact path="/python-basics" component={PythonBasics} />
            <Route exact path="/github-basics" component={PythonBasics} />
            {/* routes for specific lesson */}
            <Route
              exact
              path="/python-basics/:slug"
              component={PythonBasicsLesson}
            />
            <Route
              exact
              path="/github-basics/:slug"
              component={PythonBasicsLesson}
            />
            {/* products */}
            <Route exact path="/products/:slug" component={ProductPage} />
            {/* stripe */}
            <Route exact path="/checkout/:lesson" component={Checkout} />
            <Route exact path="/success/" component={Success} />
            <Route exact path="/canceled/" component={Canceled} />
            {/* home */}
            <Route exact path="/" component={Home} />
          </div>
          {/* <Footer /> */}
        </AppContext.Provider>
      </Router>
    </div>
  );
};

export default App;
