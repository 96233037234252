import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { codingTutorials } from "../../lib/lib";

const TutorialsHome = (props) => {
  const { flexDir, cardSize, numberToShow } = props;
  const [arrayForDisplay, setArrayForDisplay] = useState();

  useEffect(() => {
    if (numberToShow) {
      setArrayForDisplay(codingTutorials.slice(0, numberToShow));
    } else {
      setArrayForDisplay(codingTutorials);
    }
  }, []);

  return (
    <div className="">
      <Helmet>
        <title>Jonathan Grossman | Fullstack Coding Tutorials</title>
        <meta
          name="description"
          content={`Looking for coding tutorials? I write tutorials on Python, Flask, JavaScript, React, Google APIs, and more. Please tell your friends.`}
        />
      </Helmet>
      <h1 className="margin-top-20">Fullstack Coding Tutorials</h1>

      <div className={`tutorial-cards-wrapper ${flexDir} margin-bottom-200`}>
        {arrayForDisplay &&
          arrayForDisplay.map((tutorial) => {
            return (
              <Link
                to={{
                  pathname: `/tutorials/${tutorial.slug}`,
                  state: { tutorial: tutorial },
                }}
                className={`tutorial-link ${cardSize}`}
              >
                <img src={tutorial.photo} className="tutorial-image" />
                <h2 className="tutorial-title">
                  {tutorial.slug.replace(/-/g, " ")}
                </h2>
                <div className="tutorial-subtitle">
                  Objective: {tutorial.objective}
                </div>
                <div className="tutorial-title">
                  {tutorial.categories.map((category) => {
                    return (
                      <span className="tutorial-category">{category}</span>
                    );
                  })}
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default TutorialsHome;
